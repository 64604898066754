import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSavePassword } from '@sentara/sentara-api-hooks-core';
import { useForm, FormProvider } from 'react-hook-form';
import {
  ChangePassword,
  ConfirmPassword,
  PasswordStrengthMeter,
  PasswordShowEmpty,
} from 'templates';
import {
  CheckIconSmall,
  useAuth,
  setStrengthBarColor,
  deleteEncryptedValue,
  errorhandler,
  userLockedOut
} from 'common';

// Import required components from SentaraUI library
import {
  Button,
  ModalOverlay,
  Paragraph,
  ModalOverlayWithoutClose,
} from '@sentaraui/optimahealth_web';
import { deleteCacheStorage } from '@platform/cachestorage';
import { GlobalContext } from 'context';
import { PASSWORD_CRITERIA } from '../../pages/forgot-password/PasswordConstant';
import { PasswordChangeProps, FormInputs } from './interface';
// Define the interface for the props passed to PasswordChange component

// Define the PasswordChange component
const PasswordChange = ({
  changePassword,
  setChangePassword,
  state,
}: PasswordChangeProps) => {
  // Initialize localization using react-i18next
  const { t } = useTranslation();
  const methods = useForm<FormInputs>({});
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;
  const auth: any = useAuth();
  // State variables for various functionalities
  const { getFeature } = useContext(GlobalContext);
  const { savePassword } = useSavePassword();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [pwdStrength, setPwdStrength] = useState(0);
  const [value, setValue] = useState<any>('');
  const [errorPassword, setPasswordError] = useState<boolean>(false);

  useEffect(() => {
    setStrengthBarColor(value, setPwdStrength);
  }, [value]);

  // Handle the "Change Password" button click
  const handleChangeButton = () => {
    setChangePassword(true);
    setIsDisabled(false);
  };

  // Handle user sign out
  const signOutHandler = async () => {
    localStorage.clear();
    deleteEncryptedValue();
    auth.logout(true);
    await deleteCacheStorage();
    window.location.href = '/sign-in';
  };

  // State for the modal
  const [isOpen, setIsOpen] = useState(false);

  // state for Account Lockout modal
  const [isAccountLockoutModalOpen, setIsAccountLockoutModalOpen] =
    useState(false);

  // Function to close the modal
  function closeModal() {
    setIsOpen(false);
    signOutHandler();
  }


  const callSavePassword = async (password: string) => {
    const response = await savePassword(
      '',
      getFeature?.AccountSettings,
      password
    );

    const errorCode = response?.errorCode;
    if (errorCode) {
      // Account lockout popup
      setPasswordError(true);
      if (
        response?.errorDetails === userLockedOut
      ) {
        setIsAccountLockoutModalOpen(true);
      } else {
        errorhandler(errorCode);
      }
    } else {
      setIsOpen(true);
      setPasswordError(false);
    }
  };
  // Handle form submission
  const formSubmitHandler = async (data: FormInputs) => {
    if (pwdStrength !== 4 || errorPassword) {
      return;
    }
    setLoader(true);
    await callSavePassword(data?.password);
    setLoader(false);
  };

  // handle cancel button
  const handleCancel = () => {
    setChangePassword(!changePassword);
    reset();
    setPwdStrength(0);
    setPasswordError(false);
  };

  // Handle key down event
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space') {
      e.preventDefault();
    }
  };

  // Determine the class name for password strength
  const inputStrengthClass =
    pwdStrength in PASSWORD_CRITERIA
      ? `strength-bar-${PASSWORD_CRITERIA[pwdStrength]}`
      : '';

  return (
    <>
      {changePassword ? (
        // Render form for changing password

        <FormProvider {...methods}>
          <form
            data-testid="passwordInputLabelContainer"
            onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className="col-xl-6 col-md-6 col-12">
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                <Paragraph
                  className="pb-3 mb-2"
                  children={t('passwordInformation')}
                />
                {/* Render ChangePassword component */}
                <ChangePassword
                  inputStrengthClass={inputStrengthClass}
                  onKeyDown={onKeyDown}
                  setValue={setValue}
                  state={state}
                  setPasswordError={setPasswordError}
                  errorPassword={errorPassword}
                  showIcon={true}
                  showPasswordPlaceholder={`${t(
                    'activation.label.passwordPlaceholder'
                  )}`}
                />
              </div>
              <div className="pt-3 pb-3 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 px-0 px-lg-2 px-md-2">
                {/* Render PasswordStrengthMeter component */}
                <PasswordStrengthMeter
                  inputStrengthClass={inputStrengthClass}
                />
              </div>
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 w-100 float-left d-flex">
                {/* Render ConfirmPassword component */}
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                  <ConfirmPassword
                    showPasswordPlaceholder={`${t(
                      'activation.label.confirmPassword'
                    )}`}
                    className={'mb-2 pos-rel'}
                    showIcon={true}
                    onKeyDown={onKeyDown}
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
              {/* Render submit button */}
              <div className="pt-2 button_container">
                <Button
                  dataTestId="submitButton"
                  variant={
                    isValid
                      ? 'user-select-none primary_btn btn_block_mob'
                      : 'primary_btn disabled_btn btn_block_mob'
                  }
                  type="submit"
                >
                  {t('settings.label.save')}
                </Button>
                <Button
                  dataTestId="cancelButton"
                  children={t('settings.label.cancel')}
                  variant={'secondary_btn btn_block_mob'}
                  onClick={handleCancel}
                />
              </div>

              {loader && <div className="loader"></div>}
            </div>

            {/* Render modal overlay when isOpen is true */}
            {isOpen && (
              <ModalOverlay
                isOpen
                onClose={closeModal}
                overlayInfo="Modal information"
              >
                <div data-testid="memberIdModal">
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <CheckIconSmall />
                  </div>
                  <h5
                    data-testid="loggedMessage"
                    className="label_logged_message"
                  >
                    {t('settings.label.logged')}{' '}
                  </h5>
                </div>
              </ModalOverlay>
            )}

            {/* Render account lockout modal overlay when isAccountLockoutModalOpen is true */}
            {isAccountLockoutModalOpen && (
              <ModalOverlayWithoutClose
                isOpen
                onClose={() => {}}
                overlayInfo={t('activation.label.maximumAttemptInfo')}
              >
                <div data-testid="maximumAttemptInfo">
                  {t('activation.errors.accountLockoutMessage')}
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <Button
                      dataTestId="okButton"
                      className="primary_btn btn_block_mob"
                      onClick={closeModal}
                    >
                      {t('appModel.label.ok')}
                    </Button>
                  </div>
                </div>
              </ModalOverlayWithoutClose>
            )}
          </form>
        </FormProvider>
      ) : (
        // Render PasswordShowEmpty component if changePassword is false
        <PasswordShowEmpty
          isDisabled={isDisabled}
          handleChangeButton={handleChangeButton}
        />
      )}
    </>
  );
};

export default PasswordChange;
