export const memberBaseUrl = 'https://www.sentarahealthplans.com';
export const termAndCondition =
  memberBaseUrl + '/company/policies/terms-of-use-and-disclaimer';
export const languagesAssistance =
  memberBaseUrl + '/company/policies/language-assistance';
export const footerNoticeOfNonDiscrimination =
  memberBaseUrl + '/notice-of-nondiscrimination';
export const footerContactUs = '/home/contact-us';
export const HCSEmployeeHealthWellnessURL = 'https://www.careatc.com/patients';
export const dropdownValue = '/home/view-tax-summary';
export const launchDarklyURL = 'https://app.launchdarkly.com/sdk/evalx/';
export const launchDarklyKey = '/contexts/';

export const proxyExpiredForm = '/proxy-expired-form';
export const benefitFocusSSOUrl= 'BenefitFocusSSOUrl';
export const vlocityEnrollBenefits= 'Vlocity/EnrollBenefits';
export const memberHome = memberBaseUrl + '/members/';
export const contactUsAnonymous = memberBaseUrl + '/members/contact-us'
export const memberLogin = memberBaseUrl + '/signin';
export const myPlanUrl = '/home/your-plan?id=';
export const formsAndDocumentsUrl = '/home/forms-and-documents';
export const authorizationUrl = '/home/authorizations';
export const memberIDCardUrl = '/home/member-id-card';
export const benefitsURL = '/home/benefits';
export const medicalClaimsURL = '/home/medical-claims';
export const paySentaraBillURL = '/home/pay-your-sentara-bill';
export const approvalUrl = '/home/proxy-approval';
export const qa_base_url = 'https://webapidevqa.sentara.com/qa4';
export const azureBaseURL = process.env.REACT_APP_BASE_URL?.includes('/qa5')
  ? qa_base_url
  : process.env.REACT_APP_BASE_URL; // Azure functions doesn't have regression environment. So, we are pointing to QA4.
export const caregiverEndPoint = azureBaseURL + '/optimamember/v1/caregiver/';
export const signIn = "/sign-in";
export const formsAndDocuments ="forms-and-documents";
export const contactUs ="contact-us";
export const yourPlan = "/home/your-plan"
export const yourPlanId = '?id=1'
export const claimsSummary = '/home/claims-summary'
export const forgotUsername = '/login/forgot-username'
export const resetPassword = '/reset-password'
export const sentaraHealthPlans = 'https://www.sentarahealthplans.com/members'
export const sentaraHealthPlansURL = 'https://www.sentarahealthplans.com/signin'
export const avmedURL = 'https://www.avmed.org';
export const paySentaraBillSignInURL = 'https://www.sentara.visitpay.com/#/Authentication';
export const avmedContactUs = avmedURL + '/contact-us';
export const avMedBrandName ='AVMED';
