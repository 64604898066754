import { useEffect, useState, useContext } from 'react';
import { t } from 'i18next';
import {
  useMedicalClaimDetails,
  useMedicalClaimEOB,
} from '@sentara/sentara-api-hooks-core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  convertStringToDate,
  convertAPIRequestFormat,
  useDeviceType,
  DeviceTypes,
  ApiWrapper,
  UserDetails,
  apiResponseSafeCheck,
  medicalClaimsURL,
  paySentaraBillURL,
  getCache,
  errorhandler,
} from 'common';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { GlobalContext } from 'context';
import {
  Table,
  PrintIcon,
  Headings,
  ButtonLink,
  DownloadIcon,
  ContactInfo,
  Loader,
  Link,
  Button,
} from '@sentaraui/optimahealth_web';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const MedicalClaimsDetails = () => {
  const { getFeature } =
    useContext(
      GlobalContext
    ); /**To display the EOB based on the launch darkly */
  const location = useLocation(); /**use location api to get location */
  const navigate = useNavigate(); /**use navigate to navigate */
  const darkgraycolor = '#333131';
  const deviceType = useDeviceType();
  const { serviceNumber } = useContext(GlobalContext);

  const { MedicalClaimEOBType } = useMedicalClaimEOB();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  const [getMedicalDetails, setMedicalDetails] = useState<any>();
  const [getMedicalService, setMedicalService] = useState<any>();
  const [getPhone, setPhone] = useState<any>({});
  const [showNoContent, setShowNoContent] = useState(false);
  const [showData, setShowData] = useState(false);
  const { MedicalClaimDetailsType } =
    useMedicalClaimDetails(); /**medical claim details API */
  const [loader, setLoader] = useState(false);
  const { data, apiError, callApi } = ApiWrapper(MedicalClaimDetailsType); // API call for Claims Details

  // To pass the default date if is is EOBSummary
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 18);
  const fromdate = convertAPIRequestFormat(currentDate);
  const todate = convertAPIRequestFormat(new Date());
  const routeParams = useParams();
  useEffect(() => {
    setLoader(true);
    setShowData(true);
    (async () => {
      let data;
      data = {
        MemberId:
          location?.state?.memberId || localStorage.getItem(UserDetails.mId),
        ClaimType: location?.state?.ClaimType || routeParams?.claimType,
        endDate: location?.state?.endDate
          ? convertAPIRequestFormat(location.state.endDate)
          : todate,
        startDate: location?.state?.startDate
          ? convertAPIRequestFormat(location.state.startDate)
          : fromdate,
      };
      let claimIdData;
      // The EOB palyload is different from the claims summary payload
      if (routeParams?.isToken === 'true') {
        claimIdData = {
          claimTokenId: routeParams?.claimToken,
          memberSuffix: routeParams?.memberSuffix,
        };
      } else {
        claimIdData = {
          ClaimId: location?.state?.ClaimId,
        };
      }
      data = { ...data, ...claimIdData };

      callApi(data, getCache());
      setPhone(JSON.parse(serviceNumber || '{}'));
    })();
  }, []);

  useEffect(() => {
    setShowData(false);
    // To check the data is not empty and the response is success
    if (data && apiResponseSafeCheck(data)) {
      const { claimDetails, typeOfService } = data;
      setMedicalDetails(claimDetails);
      setMedicalService(typeOfService);
    } else if (data === '' || data === undefined) {
      setShowNoContent(true);
    }
    // To remove the loader when the API response is success or error
    if (apiError || data || data === '') {
      setLoader(false);
    }
    localStorage.setItem(UserDetails.memberSuffix, '');
  }, [data, apiError]);

  const Print = (e: any) => {
    e.preventDefault();
    window.print();
  };

  // Generate PDF for EOB based on claim data
  const eobPDF = async (claimID: string) => {
    setLoader(true);
    const postData = {
      claimID: claimID,
      claimType: location?.state?.ClaimType || routeParams.claimType,
      memberId:
        location?.state?.memberId || localStorage.getItem(UserDetails.mId),
    };
    const response = await MedicalClaimEOBType(postData, getCache());
    if (typeof response?.data === 'string') {
      pdfConverter(response.data, claimID);
    } else {
      errorhandler(response?.errorCode);
    }
    setLoader(false);
  };

  // Function to generate PDF from data
  const pdfConverter = (data: string, claimID: string) => {
    const filename = claimID;
    const binary = atob(data?.replace(/([^\r])\n/g, '$1\r\n'));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    } // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    saveAs(blob, filename);
  };

  //navigate back to medical claims page when click on back button
  const backToMedicalClaims = () => {
    if (routeParams?.isToken === 'true') {
      navigate(medicalClaimsURL);
    } else {
      navigate(-1);
    }
  };

  /* download PDF function to download PDF document */
  const DownloadPDF = (e: any) => {
    e.preventDefault();
    const capture = document.querySelector('.print-table') as HTMLElement;
    html2canvas(capture).then((canvas: HTMLCanvasElement) => {
      const imgData = canvas.toDataURL('image/png');
      const doc = new jsPDF();
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      doc.save(`${getMedicalDetails?.claimNumber}.pdf`);
    });
  };

  return (
    <div>
      {loader && <Loader />}
      <div className="row flex-lg-row flex-md-row flex-sm-column gap-4 align-items-lg-center align-items-md-center align-items-sm-start not-print mb-4">
        <div className="flex-1 col-lg-auto col-md-auto col-sm-12 col-12 ms-lg-2 ms-md-2 ms-4">
          <Button
            dataTestId="backToClaimList"
            variant="link-text btn-links arrow-back"
            onClick={backToMedicalClaims}
          >{t('claimsSummary.label.goback')}</Button>
        </div>
        <div className="col-md-auto col-lg-auto col-sm-12 col-12 text-center">
          {isMobile && (
            <div className="d-flex justify-content-center secondary_btn btn-medium w-100">
              <ButtonLink
                buttonText={t('authorizationDetails.label.print')}
                dataTestId="printLink"
                icon={<PrintIcon />}
                onClick={Print}
              />
            </div>
          )}
          {!isMobile && (
            <ButtonLink
              buttonText={t('authorizationDetails.label.print')}
              dataTestId="printLink"
              icon={<PrintIcon />}
              onClick={Print}
            />
          )}
        </div>
        <div className="col-md-auto col-lg-auto col-sm-12 col-12 text-center">
          {isMobile && (
            <div className="d-flex justify-content-center secondary_btn btn-medium w-100">
              <ButtonLink
                buttonText={t('claimsSummary.label.download')}
                dataTestId="downloadClaimsDetailsLink"
                icon={<DownloadIcon color={darkgraycolor} />}
                onClick={DownloadPDF}
              />
            </div>
          )}
          {!isMobile && (
            <ButtonLink
              buttonText={t('claimsSummary.label.download')}
              dataTestId="downloadClaimsDetailsLink"
              icon={<DownloadIcon color={darkgraycolor} />}
              onClick={DownloadPDF}
            />
          )}
        </div>
      </div>
      <div className="print-table print">
        <div className="heading-one-semibold mb-3">
          <Headings
            level={1}
            text={t('claimsSummary.label.claimDetails')}
            dataTestId="claimsDetailsHeader"
          />
        </div>
        <div className="print mb-4">
          {!showData && getMedicalDetails !== undefined && (
            <Table
              rows={[
                [
                  {
                    text: (
                      <strong data-testid="memberNameLabel">
                        {t('claimsSummary.label.memberName')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="memberNameValue">
                        {getMedicalDetails?.memberName}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="memberIdLabel">
                        {t('claimsSummary.label.memberID')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="memberIdValue">
                        {getMedicalDetails?.memberId}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="claimNumberLabel">
                        {t('claimsSummary.label.claimNumber')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="claimNumberValue">
                        {getMedicalDetails?.claimNumber}
                      </span>
                    ),
                  },
                ],

                [
                  {
                    text: (
                      <strong data-testid="dateOfServiceLabel">
                        {t('claimsSummary.label.dateOfService')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="dateOfServiceValue">
                        {getMedicalDetails?.datesOfService &&
                          convertStringToDate(
                            getMedicalDetails?.datesOfService
                          )}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="typeOfServiceLabel">
                        {t('claimsSummary.label.typeOfService')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="typeOfServiceValue">
                        {getMedicalDetails?.typesofService}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="practiceNameLabel">
                        {t('claimsSummary.label.practiceName')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="practiceNameValue">
                        {getMedicalDetails?.practiceName}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="provideNameLabel">
                        {t('claimsSummary.label.providerName')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="provideNameValue">
                        {getMedicalDetails?.providerName}
                      </span>
                    ),
                  },
                ],

                [
                  {
                    text: (
                      <strong data-testid="claimStatusLabel">
                        {t('claimsSummary.label.claimsStatus')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="claimStatusValue">
                        {getMedicalDetails?.claimsStatus}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="totalChargesLabel">
                        {t('claimsSummary.label.totalCharges')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="totalChargesValue">
                        {getMedicalDetails?.totalCharges &&
                          `$${getMedicalDetails?.totalCharges}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="totalNotCoveredLabel">
                        {t('claimsSummary.label.totalNotCovered')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="totalNotCoveredValue">
                        {getMedicalDetails?.tot_nv &&
                          `$${getMedicalDetails?.tot_nv}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="totalCoveredLabel">
                        {t('claimsSummary.label.totalCovered')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="totalCoveredValue">
                        {getMedicalDetails?.tot_cov &&
                          `$${getMedicalDetails?.tot_cov}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="totalDeductibleLabel">
                        {t('claimsSummary.label.totalDeductible')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="totalDeductibleValue">
                        {getMedicalDetails?.totalDeductible &&
                          `$${getMedicalDetails?.totalDeductible}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="totalCopayCoinsuranceLabel">
                        {t('claimsSummary.label.totalCopayCoinsurance')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="totalCopayCoinsuranceValue">
                        {getMedicalDetails?.tot_copay &&
                          `$${getMedicalDetails?.tot_copay}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="totalPaidByPlanLabel">
                        {t('claimsSummary.label.totalPaidByPlan')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="totalPaidByPlanValue">
                        {getMedicalDetails?.tot_paid &&
                          `$${getMedicalDetails?.tot_paid}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="patientResponsibilityLabel">
                        {t('claimsSummary.label.patientResponsibility')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="patientResponsibilityValue">
                        {getMedicalDetails?.tot_resp &&
                          `$${getMedicalDetails?.tot_resp}`}
                      </span>
                    ),
                  },
                ],
                [
                  {
                    text: (
                      <strong data-testid="datePaymentSendToProviderLabel">
                        {t('claimsSummary.label.datePaymentSentToProvider')}
                      </strong>
                    ),
                  },
                  {
                    text: (
                      <span data-testid="datePaymentSendToProviderValue">
                        {getMedicalDetails?.datePaymentSent &&
                          convertStringToDate(
                            getMedicalDetails?.datePaymentSent
                          )}
                      </span>
                    ),
                  },
                ],
                getFeature?.Eob &&
                (routeParams?.isEOB == 'true' ||
                  location?.state?.isEOB == 'true')
                  ? [
                      {
                        text: (
                          <ButtonLink
                            buttonText={t(
                              'claimsSummary.label.viewExplanationOfBenefits'
                            )}
                            dataTestId="downloadClaimsDetailsLink"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              eobPDF(getMedicalDetails?.claimNumber);
                            }}
                          />
                        ),
                      },
                    ]
                  : null,
              ]}
            />
          )}

          {/* To display the no content message when the data is empty */}
          {!showData && showNoContent && (
            <p className="redhat_regular fw-400 ms-1">{t('noResults')}</p>
          )}
        </div>
        {getMedicalService && getMedicalService.length > 0 && (
          <>
            <div className="w-100 print" data-testid="typeofService">
              <Headings
                className="literata_semibold black-color font-fourty mb-4"
                level={2}
                text={t('claimsSummary.label.typeofService')}
              />
                
            </div>
            <div className="table-responsive">
              <Table
                className={' mb-4 print'}
                headerChildren={
                  <>
                    <th>
                      <span
                        data-testid="authNumberTab"
                        className="visually-hidden"
                      >
                        {t('claimsSummary.label.amountDetails')}
                      </span>
                    </th>
                    {getMedicalService?.map((item: any, i: any) => {
                      return (
                        <th
                          data-testid="radiologyTab"
                          key={`medical-service-${item.serviceName}`}
                        >
                          {item.serviceName}
                        </th>
                      );
                    })}
                  </>
                }
                rowsChildren={
                  <>
                    <tr>
                      <td data-testid={`amountChargedLabel`}>
                        <strong>
                          {t('claimsSummary.label.amountCharged')}
                        </strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item${item?.amountCharged}`}
                              data-testid={`amountChargedValue`}
                            >
                              {item?.amountCharged && `$${item?.amountCharged}`}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td data-testid={`amountNotCoveredLabel`}>
                        <strong>
                          {t('claimsSummary.label.amountNotCovered')}
                        </strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.amountNotCovered}`}
                              data-testid={`amountNotCoveredValue`}
                            >
                              {item?.amountNotCovered &&
                                `$${item?.amountNotCovered}`}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td data-testid={`amountAllowedLabel`}>
                        <strong>
                          {t('claimsSummary.label.amountAllowed')}
                        </strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.amountAllowed}`}
                              data-testid={`amountAllowedValue`}
                            >
                              {item?.amountAllowed && `$${item?.amountAllowed}`}
                            </td>
                          );
                        })}
                    </tr>

                    <tr>
                      <td data-testid={`deductibleLabel`}>
                        <strong>{t('claimsSummary.label.deductible')}</strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.deductible}`}
                              data-testid={`deductibleValue`}
                            >
                              {item?.deductible && `$${item?.deductible}`}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td data-testid={`copayCoinsuranceLabel`}>
                        <strong>
                          {t('claimsSummary.label.copayCoinsurance')}
                        </strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.copay}`}
                              data-testid={`copayCoinsuranceValue`}
                            >
                              {item?.copay && `$${item?.copay}`}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td data-testid={`amountPaidByPlanLabel`}>
                        <strong>
                          {t('claimsSummary.label.amountPaidByPlan')}
                        </strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.amountPaidByPlan}`}
                              data-testid={`amountPaidByPlanValue`}
                            >
                              {item?.amountPaidByPlan &&
                                `$${item?.amountPaidByPlan}`}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td data-testid={`patientResponsibilityLabel`}>
                        <strong>
                          {t('claimsSummary.label.patientResponsibility')}
                        </strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.patientResp}`}
                              data-testid={`patientResponsibilityValue`}
                            >
                              {item?.patientResp && `$${item?.patientResp}`}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td data-testid={`typeOfServiceNoteLabel`}>
                        <strong>{t('claimsSummary.label.note')}</strong>
                      </td>
                      {getMedicalService &&
                        getMedicalService?.length > 0 &&
                        getMedicalService?.map((item: any, i: any) => {
                          return (
                            <td
                              key={`item-${item?.noteCode}`}
                              data-testid={`typeOfServiceNoteValue`}
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    data-testId="button_tooltip"
                                    className="button_tooltip"
                                    id="button_tooltip"
                                  >
                                    {item?.noteDesc}
                                  </Tooltip>
                                }
                              >
                                <span className='ws-nowrap text-decoration-underline'>
                                  {' '}
                                  {item?.noteCode && `${item?.noteCode}`}
                                </span>
                              </OverlayTrigger>
                            </td>
                          );
                        })}
                    </tr>
                  </>
                }
              />
          
            </div>
          </>
        )}
      </div>
      {getFeature?.PayYourSentaraBill && (
        <div className='mt-2 mb-4'>
          <Button
            variant="primary_btn w-40"
            dataTestId={'PaySentaraMedicalBill'}
            onClick={(e: any) => {
              e.preventDefault();
              navigate(paySentaraBillURL);
            }}
          >
            {t('claimsSummary.label.paySentaraMedicalBill')}
          </Button>
        </div>)
      }
      <div className="disclaimer-text">
        <p data-testid="typeOfServiceDisclaimerText" className="mb-4">
          {t('claimsSummary.message.mouseOverNote')}
        </p>
        <div>
          <p className="mb-4" data-testid="claimsInstruction">
            <span>{t('claimsSummary.message.claimsInstruction1')}</span>
            {apiResponseSafeCheck(getPhone?.data?.benefitPhone1800) ? (
              <>
                <span>
                  {' '}
                  {t('claimsSummary.message.claimsInstructionMemberServices')}
                </span>
                <Link
                  to={`tel:${getPhone?.data?.benefitPhoneLocal}`}
                  className="ws-nowrap text-decoration-underline"
                >
                  {' '}
                  {getPhone?.data?.benefitPhoneLocal}
                  {t('dot')}
                </Link>
              </>
            ): null}
          </p>
          <p className="mb-5" data-testid="disclaimer5">
            {t('claims.label.disclaimer5')}{' '}
            <Link
              to={`mailto:${t('claims.label.disclaimer6')}`}
              className="ws-nowrap text-decoration-underline"
              data-testid="customerMailId"
            >
              {t('claims.label.disclaimer6')}
            </Link>{' '}
            {apiResponseSafeCheck(getPhone?.data) ? (
              <>
                {t('claims.label.disclaimer7')}{' '}
                <ContactInfo
                  phoneData={{
                    phone1800: getPhone?.data?.benefitPhone1800,
                    phoneLocal: getPhone?.data?.benefitPhoneLocal,
                  }}
                />
              </>
            ): null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MedicalClaimsDetails;