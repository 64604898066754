import { paySentaraBillSignInURL } from '../../common/utils/constants/ConstantURL';
import { useTranslation } from 'react-i18next';
import CommonClaims from 'templates/CommonClaims';

function PayYourSentaraBill() {
  //  Initialize useTranslation hook
  const { t } = useTranslation();
  return (
    <CommonClaims
      staticUrlLink={paySentaraBillSignInURL ?? ''}
      claimTitle={t('navigation.label.payYourSentaraBill')}
      claimMsg={t('claims.message.billPaymentMsg')}
    />
    
      
  );
}

export default PayYourSentaraBill;
