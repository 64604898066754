import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { signIn, userLockedOut,sessionTimedOut, kbaFailureError  } from 'common';
import {
  useForgetPasswordToken,
  useSecurityQuestionAnswer,
} from '@sentara/sentara-api-hooks-core';

export const useSecurityQuestion = () => {
  const [securityQuestion, setSecurityQuestion] = useState<any>({});
  const [securityAnswer, setSecurityAnswer] = useState<string>('');
  const [securityAnswerError, setSecurityAnswerError] = useState<string>('');
  const [securityAnswerResponse, setSecurityAnswerResponse] = useState<any>({});
  const [showSecurityQuestion, setShowSecurityQuestion] =
    useState<boolean>(false);
  const [securityQuestionSuccess, setSecurityQuestionSuccess] =
    useState<boolean>(false);
  const [tokenExpiry, setTokenExpiry] = useState<boolean>(false);
  const { forgetPasswordToken } = useForgetPasswordToken();
  const { forgetPasswordSecretAnswer } = useSecurityQuestionAnswer();
  const navigate = useNavigate();

  /* to get params from URL */
  let search = window.location.search;
  let newParams = new URLSearchParams(search);
  let securityAnswerEncodeToken: string = newParams.get('token') || '';

  let securityAnswerDecodeToken: string = newParams.get('token') || '';
  let decodedParam = decodeURI(securityAnswerDecodeToken);
  // when %20 is decoded to Space and sent to the Reset password API its throwing 462 error so converted to Plus sign as per legacy
  securityAnswerDecodeToken = decodedParam.replace(/ /g, '+');

  useEffect(() => {
    (async () => {
      if (securityAnswerDecodeToken !== null) {
        let tokenId = { encryptedValue: securityAnswerDecodeToken };
        const response = await forgetPasswordToken(tokenId);
        if (response?.errorCode) {
          const unAuthorizedError = [400, 401, 404, 410, 462, 500];
          const unAuthError = unAuthorizedError.includes(
            Number(response?.errorCode)
          );
          if (unAuthError) {
            setTokenExpiry(true);
          }
          if (+response?.errorCode === 500) {
            setSecurityAnswerError(t('internalServerError') || '');
            setShowSecurityQuestion(true);
          }
        }
        setSecurityQuestion(response?.data);
      }
    })();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSecurityAnswer(e.target.value);
    if (value.length >= 3) {
      setShowSecurityQuestion(false);
    } else {
      setShowSecurityQuestion(true);
      setSecurityAnswerError(
        t('requestUserId.message.errorMessage') || ''
      );
    }
  };

  const onSubmit = async () => {
    if (securityAnswer?.length <= 2) {
      setShowSecurityQuestion(true);
      setSecurityAnswerError(
        t('requestUserId.message.errorMessage') || ''
      );
      return false;
    }
    if (securityAnswerDecodeToken !== null) {
      let data = {
        question: securityQuestion?.KBAQuestion,
        answer: securityAnswer,
        authId: securityQuestion?.authId,
        token: securityAnswerDecodeToken
      };
      const response: any = await forgetPasswordSecretAnswer(data);
      if (response?.errorCode) {
        const unAuthorizedError = [400, 404, 410, 462, 500];
        const unAuthError = unAuthorizedError.includes(
          Number(response?.errorCode)
        );
        if (unAuthError) {
          setTokenExpiry(true);
        }
        if(response?.errorDetails === userLockedOut ) {
          setSecurityAnswerError(
            t('activation.errors.accountLockoutMessage') ?? ''
          );
          setShowSecurityQuestion(true);
        }else if(response?.errorDetails === sessionTimedOut){
          setTokenExpiry(true)
        }else if (response?.errorDetails === kbaFailureError) {
          setSecurityAnswerError(t('requestUserId.message.serviceError') ?? '');
          setShowSecurityQuestion(true);
        }else if (response?.errorCode === 401) {
            setSecurityAnswerError(response?.errorDetails);
            setShowSecurityQuestion(true);
        }
      }
      if (response?.status === 200) {
        setSecurityAnswerResponse(response?.data);
        setSecurityQuestionSuccess(true); // If the response is successful, navigate to the ResetPassword page
        setShowSecurityQuestion(false);
      }
    } else {
      setShowSecurityQuestion(true); // Show the error message shown in the UI and disable the submit button
    }
  };

  const handleCancel = () => {
    navigate(signIn);
  };

  return {
    handleCancel,
    handleChange,
    onSubmit,
    securityAnswer,
    securityAnswerError,
    securityAnswerResponse,
    securityQuestion,
    securityAnswerEncodeToken,
    securityAnswerDecodeToken,
    showSecurityQuestion,
    securityQuestionSuccess,
    tokenExpiry,
  };
};
