import { useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';
import { errorhandler } from 'common';
import { GlobalContext } from 'context';
import CommonClaims from 'templates/CommonClaims';

function PharmacyClaims() {
  //  Initialize useTranslation hook
  const { t } = useTranslation();
  // Initialize SSO URL hook
  const { SSOUrlType } = useMySSOUrl();
  const { getFeature } = useContext(GlobalContext);
  const [urllink, setUrllink] = useState<string | null>(null);


  // Function to get and open the API URL in a new tab
  const getAPIUrl = async () => {
    let data = getFeature?.PharmacyBenefitsManager ? t('claims.message.PharmacyBenefitsManager') : t('claims.message.OptumRX');
    const response = await SSOUrlType(data); // Get SSO URL from API
    if (response && response?.data?.ssoUrl) {
      setUrllink(response.data.ssoUrl); // Set the result state
    } else if (response?.errorCode) {
      errorhandler(response?.errorCode);
    }
  };

   // Call getAPIUrl when the component mounts
   useEffect(() => {
    getAPIUrl();
  }, []);

  return (
    <CommonClaims
      staticUrlLink={urllink ?? ''}
      claimTitle={t('navigation.label.pharmacy')}
      claimMsg={t('claims.message.pharmacyMsg')}
    />
  );
}

export default PharmacyClaims;
